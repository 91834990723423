import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { IconXCircle } from '@veneer/core'
import LoaderContent from '../LoaderContent'
import { Container, ErrorText, ErrorWrapper, LoaderWrapper } from './styles'
import { useEffect } from 'react'

interface MicrofrontendProps {
  component?: string
  fullScreen?: boolean
  setLastUpdated?: any
  [key: string]: unknown
}

const EmptyCard = () => (
  <LoaderWrapper appearance="dropShadow" content={<LoaderContent />} />
)

const ErrorFallback = () => (
  <ErrorWrapper
    appearance="dropShadow"
    content={
      <>
        <IconXCircle color="colorRed6" filled size={48} />
        <ErrorText>An error has occurred while loading this widget!</ErrorText>
      </>
    }
  />
)

export const MicroFrontend: React.FC<MicrofrontendProps> = ({
  component,
  ...props
}) => {
  const Card = React.lazy(() => System.import(component))

  useEffect(() => {
    props.setLastUpdated()
  }, [window.location.pathname])

  return (
    <Container>
      {component ? (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <React.Suspense fallback={<EmptyCard />}>
            <Card {...props} />
          </React.Suspense>
        </ErrorBoundary>
      ) : (
        <LoaderWrapper appearance="dropShadow" />
      )}
    </Container>
  )
}
