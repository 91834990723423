import styled from 'styled-components'

/*
 * Base components for pages
 */

export const Container = styled.div`
  width: 100vw;
  min-height: fit-content;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 24px 24px 24px;
  position: relative;
  background: #f8f8f8;

  & > * {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const TitleContent = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;

    &:first-child:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const Title = styled.h2`
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #2b2b2b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 12px;
`

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 150% */
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 16px;
`

/*
 * Grid Layout Components: used in dashboard / pages
 */
export const Grid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 640px 300px;
  -ms-grid-columns: 640px 300px;
  grid-gap: 32px 32px;
  margin: 32px;
  @media (max-width: 1036px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    -ms-grid-column-span: 1fr;
  }
`

export const GridSeparator = styled.div`
  // max-width: 975px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: HP Simplified Light) {
    width: inherit;
    flex: 1;
  }
`

export const MainContent = styled.div`
  > * {
    &:not(:last-child) {
      :not(:empty) {
        margin-bottom: 32px;
      }
    }
  }
`
