import React from 'react'
import { ProgressIndicator } from '@veneer/core'
import PropTypes from 'prop-types'

import { Container } from './styles'

const Loader = ({ fullScreen }) => (
  <Container fullScreen={fullScreen}>
    <ProgressIndicator />
  </Container>
)

Loader.defaultProps = {
  fullScreen: false
}

Loader.propTypes = {
  fullScreen: PropTypes.bool
}

export default Loader
