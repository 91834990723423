import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import { Container } from './styles';

const LoaderContent = ({ fullScreen }) => (
  <Container>
    <Loader fullScreen={fullScreen} />
  </Container>
);

LoaderContent.defaultProps = {
  fullScreen: false,
};

LoaderContent.propTypes = {
  fullScreen: PropTypes.bool,
};

export default LoaderContent;
