import React from 'react'
import SecurityTab from '../src/components/SecurityTab'
import { ShellProps } from './types/shell'
import packageInfo from '../package.json'
import { RootProvider } from '@jarvis/react-portal-addons'
import resources from '../src/assets/locale/index'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root(props) {
  const shellProps = window.Shell as ShellProps
  const breadcrumbs = shellProps.v1?.breadcrumbs
  const events = shellProps.v1?.events
  const namespace = '@jarvis/react-ecp-endpointsecurity-homepage'

  return (
    <section id={namespace}>
      <RootProvider localization={props.localization} resources={resources}>
        <SecurityTab
          breadcrumbs={breadcrumbs}
          events={events}
          id={packageInfo.name}
          shell={shellProps}
          {...props}
          country={props.localization.country ? props.localization.country : 'us'}
          language={
            props.localization.language ? props.localization.language : 'en'
          }
          localization={props.localization ? props.localization : 'en-us'}
        />
      </RootProvider>
    </section>
  )
}
