export const DASHBOARD_PATH = '/solutions/security'
export const DASHBOARD_MFE = '@jarvis/react-ecp-endpointsecurity-dashboard'
export const DASHBOARD_MFE_TYPE = 'ECPDashboard'

export const DEVICES_SUFFIX = '/devices'
export const DEVICES_PATH = DASHBOARD_PATH + DEVICES_SUFFIX
export const DEVICES_MFE = '@jarvis/react-ecp-endpointsecurity-devicespage'
export const DEVICES_MFE_TYPE = 'ECPDevices'

export const DASHBOARD_CARD = 'ws-hp.com/smcloud/fleetassessment.READ'

export const DEVICE_CARD = 'ws-hp.com/collections/contents.devices.READ'

export const DASHBOARD_TAB_SCOPES = [{ scope: DASHBOARD_CARD }]
export const DEVICE_TAB_SCOPES = [{ scope: DEVICE_CARD }]
