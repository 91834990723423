/* eslint-disable prettier/prettier */
import { JarvisAuthProvider } from '@jarvis/web-http'

type ManageDeviceProps = {
  country?: string
  language?: string
  authProvider: JarvisAuthProvider
}

let localprops: ManageDeviceProps = {
  language: 'en',
  country: 'US',
  authProvider: null
}

export const getProps = (): ManageDeviceProps => {
  return localprops
}

export const setProps = (props) => {
  localprops = props
}

export function changeBreadcrumbs(breadcrumbs, navigation, t) {
  if (breadcrumbs && breadcrumbs.isEnabled()) {
    breadcrumbs.getBreadcrumbs().forEach((bread, i) => {
      if (i > 1) {
        breadcrumbs.remove(bread.key)
      }

      const navLength = navigation.location.pathname.split('/').length
      const isDevices = navLength === 4 || navLength === 5
      const isOverview = navLength === 3

      if (isDevices) {
        breadcrumbs.add({
          key: 'devices',
          text: `${t(
            'ecp-endpointsecurity-homepage.heading',
            'HP Secure Fleet Manager'
          )}: ${t('ecp-endpointsecurity-homepage.devices', 'Devices')}`
        })
      }
      // if (navLength === 5) {
      //   breadcrumbs.add({
      //     key: 'device-details',
      //     text: t('details')
      //   })
      // }
      if (isOverview) {
        breadcrumbs.add({
          key: 'overview',
          text: `${t(
            'ecp-endpointsecurity-homepage.heading',
            'HP Secure Fleet Manager'
          )}: ${t('ecp-endpointsecurity-homepage.overview', 'Overview')}`
        })
      }
    })
  }
}
