import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { SecurityMain } from '../SecurityMain'
import { GlobalHeader } from '../GlobalBanner'

import { Tabs } from '@veneer/core'
import { MicroFrontend } from '../MicroFrontend'
import { setProps } from '../../utils/const'
import Moment from 'moment'
import {
  DASHBOARD_TAB_SCOPES,
  DEVICE_TAB_SCOPES,
  DEVICES_MFE_TYPE,
  DASHBOARD_MFE_TYPE,
  DASHBOARD_MFE,
  DEVICES_MFE,
  DASHBOARD_PATH,
  DEVICES_PATH
} from '../../constants/constant'
import { changeBreadcrumbs } from '../../utils/commonMethods'
import { useI18n } from '@jarvis/react-portal-addons'

const SecurityTab = (props) => {
  let navigationId = []
  setProps(props)
  const {
    stack,
    shell,
    accessControl,
    localization,
    breadcrumbs,
    events,
    navigation
  } = props
  const { language, country } = localization
  const showDashboard = accessControl && accessControl.checkScopes(DASHBOARD_TAB_SCOPES)
  const showDevice = accessControl && accessControl.checkScopes(DEVICE_TAB_SCOPES)

  const [currentTab, setCurrentTab] = useState<number | undefined>(
    getCurrentTab()
  )
  const [isDeviceDetailsPage, setIsDeviceDetailsPage] = useState(
    navigation.location.pathname.split('/').length === 5
  )
  const [lastUpdated, setLastUpdated] = useState(Moment(new Date()))
  const defaultPath = navigation.createHref({
    pathname: '/solutions/security'
  })
  const { t } = useI18n()
  function tabHandler() {
    setCurrentTab(getCurrentTab())
    changeBreadcrumbs(breadcrumbs, navigation, t)
    setIsDeviceDetailsPage(navigation.location.pathname.split('/').length === 5)
  }

  useEffect(() => {
    window.addEventListener('popstate', tabHandler)
    return () => window.removeEventListener('popstate', tabHandler)
  }, [])

  useEffect(() => {
    if (!showDashboard && !showDevice) {
      navigation.push(`/solutions/security`)
    } else if (!showDashboard) {
      navigation.push(`/solutions/security/devices`)
    } else if (!showDevice) {
      navigation.push(`/solutions/security`)
    }
  }, [showDashboard, showDevice])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', onUpdate)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', onUpdate)
    }
  }, [events])

  const getPath = (param) => {
    const currentPath = window.location.pathname
    const path = defaultPath + param
    return currentPath !== path ? '/solutions/security' + param : undefined
  }

  function getCurrentTab() {
    const pathArray = navigation.location.pathname.split('/')
    const tabPath = pathArray[3]

    if (typeof pathArray[4] !== 'undefined') {
      navigationId = pathArray[4]
    }

    return showDashboard && showDevice && tabPath === 'devices' ? 1 : 0
  }
  const changeURL = (id) => {
    if (showDashboard && showDevice) {
      const path = window.location.pathname
      const newPath = id === 1 ? getPath('/devices') : '/solutions/security'
      if (path !== defaultPath || id === 1) {
        navigation.push(newPath)
      }
    }
  }

  const onUpdate = () => setLastUpdated(Moment(new Date()))

  const TabMfe = useCallback(
    ({ component, type }) => {
      return (
        <div style={{ padding: '16px 0px 0px 0px' }}>
          <MicroFrontend
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
              country,
              language,
              setLastUpdated,
              navigationId
            }}
          />
        </div>
      )
    },
    [shell, stack, props, navigationId]
  )

  const tabs = [
    {
      id: 0,
      label: t('ecp-endpointsecurity-homepage.overview', "Overview"),
      content: (
        <TabMfe
          type={DASHBOARD_MFE_TYPE}
          component={DASHBOARD_MFE}
          path={DASHBOARD_PATH}
        />
      )
    },
    {
      id: showDashboard ? 1 : 0,
      label: t('ecp-endpointsecurity-homepage.devices', "Devices"),
      content: (
        <TabMfe
          type={DEVICES_MFE_TYPE}
          component={DEVICES_MFE}
          path={DEVICES_PATH}
        />
      )
    }
  ].slice(showDashboard ? 0 : 1, showDevice ? 2 : 1)

  return (
    <div className="securityMain">
      <GlobalHeader
        titleText={isDeviceDetailsPage ? undefined : t('ecp-endpointsecurity-homepage.heading', "HP Secure Fleet Manager")}
      />
      <SecurityMain
        navigation={navigation}
        breadcrumbs={breadcrumbs}
        t={t}
        events={events}
        direction="ltr"
        fullWidth={true}
        lastUpdated={lastUpdated}
        onUpdate={onUpdate}
        showLastUpdate={tabs && tabs.length > 0}
        mainContent={
          tabs?.length > 0 ? (
            <Tabs
              controlId="extended"
              mode="extended"
              onChangeTab={(id: number) => changeURL(id)}
              selectedTabId={currentTab}
              tabs={tabs}
              data-testid="endpoint-security-tabs"
            />
          ) : null
        }
      />
    </div>
  )
}

SecurityTab.defaultProps = {
  shell: {},
  stack: null
}

SecurityTab.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number
}

export default SecurityTab
