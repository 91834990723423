import React, { useCallback } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'

export const GlobalHeader = (props) => {
  const SubMfe = useCallback(
    ({ component, title, type }) => {
      return (
        <MicroFrontend
          disableContainerPadding
          {...props}
          {...{
            component,
            title,
            type
          }}
        />
      )
    },
    [props]
  )

  // title : Optional property
  // title: null - displays default title of the page on the banner
  // title: customTitle - displays custom title

  if (props.titleText)
    // With custom title
    return (
      <SubMfe
        type="headerTitle"
        title={props.titleText}
        component="@jarvis/react-ecp-header"
      />
    )
  // Without Custom title
  else return <SubMfe type="headerTitle" component="@jarvis/react-ecp-header" />
}
