import styled from 'styled-components'
import { Card } from '@veneer/core'

export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 100%;

  & > * {
    width: 100%;
    max-width: 100%;
  }
`

export const LoaderWrapper = styled(Card)`
  width: 100%;
  height: 100%;
`

export const ErrorWrapper = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ErrorText = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: 'red';
  text-align: center;
  max-width: 250px;
  margin-top: 16px;
`
