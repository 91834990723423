import React, { ReactElement } from 'react'
import { Container, Grid, MainContent } from './styles'
import { changeBreadcrumbs } from '../../utils/commonMethods'

export type TabsId = number | string
/**
 * JarvisPortalPage Properties
 */
type SecurityProps = {
  /** The title to display above the component */
  // title: string

  /** The Subtitle to display above the component */
  // description: string

  /** The main react element to render on the page*/
  mainContent: ReactElement
  navigation: any
  breadcrumbs: any
  events: any
  /** Direction to display content */
  direction: string
  t: any

  /** If false, content is a grid (cols = 640px, 300px) if window is bigger than 1280px */
  fullWidth?: boolean

  lastUpdated: any

  onUpdate: any

  showLastUpdate: Boolean
}

/**
 * React component that renders a UCDE Style Page.
 * @component
 * @param {SecurityProps} props The react props
 */

export const SecurityMain = (props: SecurityProps) => {
  const {
    direction,
    breadcrumbs,
    mainContent,
    fullWidth,
    navigation,
    t
  } = props

  const ContentWrapper = React.useMemo(() => {
    return fullWidth ? 'div' : Grid
  }, [fullWidth])
  return (
    <Container dir={direction}>
      {changeBreadcrumbs(breadcrumbs, navigation, t)}
      <ContentWrapper>
        <MainContent data-testid="endpoint-security-main-content">
          {mainContent}
        </MainContent>
      </ContentWrapper>
    </Container>
  )
}
